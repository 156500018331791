#volume-slider {
  cursor: pointer;
  transition-property: width;
  transition-duration: 0.3s;
  transition-delay: 0.5s;
  width: 0;
}

#volume-control:hover #volume-slider {
  transition-delay: 0s;
  width: 70px;
}

#volume-control #volume-slider-knob {
  border-radius: 50%;
  transition-property: width, height;
  transition-duration: 0.3s;
  transition-delay: 0.5s;
  width: 4px;
  height: 4px;
}

#volume-control:hover #volume-slider-knob {
  transition-delay: 0s;
  width: 12px;
  height: 12px;
}

#volume-control #volume-slider-spacer {
  transition-property: width, height;
  transition-duration: 0.3s;
  transition-delay: 0.5s;
  width: 2px;
  height: 2px;
}

#volume-control:hover #volume-slider-spacer {
  transition-delay: 0s;
  width: 12px;
  height: 12px;
}
